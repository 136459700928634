import { useTranslate } from '/machinery/I18n'
import { ButtonIconWhite } from '/features/buildingBlocks/ButtonIcon'

import styles from './SliderButtons.css'
import arrowIcon from '/images/icons/arrow.raw.svg'

export function SliderButtons({ handleClick, currentSlide, previousButtonDisabled, nextButtonDisabled, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <SliderButton
        onClick={() => handleClick(currentSlide - 1)}
        disabled={previousButtonDisabled}
        ariaLabel={__`go-to-previous-slide-button`}
      />
      <SliderButton
        onClick={() => handleClick(currentSlide + 1)}
        disabled={nextButtonDisabled}
        ariaLabel={__`go-to-next-slide-button`}
      />
    </div>
  )
}

function SliderButton({ onClick, disabled, ariaLabel }) {
  return (
    <span className={styles.componentSliderButton}>
      <ButtonIconWhite
        icon={arrowIcon}
        dataX='click-slider-button'
        layoutClassName={styles.buttonIconLayout}
        {...{ onClick, disabled, ariaLabel }}
      />
    </span>
  )
}
