import { Loader } from '/features/buildingBlocks/Loader'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ButtonIcon.css'

export function ButtonIconWithLabel({ onClick, icon, label, dataX, ariaLabel, isProcessing = undefined, isActive = undefined, disabled = undefined, layoutClassName = undefined }) {
  return (
    <ButtonIconWithLabelBase
      renderButtonIconComponent={() =>
        <ButtonIconWhite layoutClassName={styles.iconCircleLayout} {...{ ariaLabel, onClick, icon, dataX, disabled, isActive, isProcessing }} />
      }
      className={cx(styles.componentWithLabel, layoutClassName)}
      {...{ onClick, icon, label, dataX, disabled, isActive, isProcessing }}
    />
  )
}

export function ButtonIconWithLabelBlue({ onClick, icon, label, dataX, ariaLabel, isProcessing = undefined, isActive = undefined, disabled = undefined, layoutClassName = undefined }) {
  return (
    <ButtonIconWithLabelBase
      renderButtonIconComponent={() =>
        <ButtonIconBlue layoutClassName={styles.iconCircleLayout} {...{ ariaLabel, onClick, icon, dataX, disabled, isActive, isProcessing }} />
      }
      className={cx(styles.componentWithLabelBlue, layoutClassName)}
      {...{ onClick, icon, label, dataX, disabled, isActive, isProcessing }}
    />
  )
}

export function ButtonIconWithLabelGray({ onClick, icon, label, dataX, ariaLabel, isProcessing = undefined, isActive = undefined, disabled = undefined, layoutClassName = undefined }) {
  return (
    <ButtonIconWithLabelBase
      renderButtonIconComponent={() =>
        <ButtonIconBlack layoutClassName={styles.iconCircleLayout} {...{ ariaLabel, onClick, icon, dataX, disabled, isActive, isProcessing }} />
      }
      className={cx(styles.componentWithLabelGray, layoutClassName)}
      {...{ onClick, icon, label, dataX, disabled, isActive, isProcessing }}
    />
  )
}

export function ButtonIconWithLabelTransparent({ onClick, icon, label, dataX, ariaLabel, isProcessing = undefined, isActive = undefined, disabled = undefined, layoutClassName = undefined }) {
  return (
    <ButtonIconWithLabelBase
      renderButtonIconComponent={() =>
        <ButtonIconTransparent layoutClassName={styles.iconCircleLayout} {...{ ariaLabel, onClick, icon, dataX, disabled, isActive, isProcessing }} />
      }
      className={cx(styles.componentWithLabelTransparent, layoutClassName)}
      {...{ onClick, icon, label, dataX, disabled, isActive, isProcessing }}
    />
  )
}

function ButtonIconWithLabelBase({ renderButtonIconComponent, label, className }) {
  return (
    <div className={cx(styles.componentWithLabelBase, className)}>
      {label}
      {renderButtonIconComponent()}
    </div>
  )
}

export function ButtonIconWhite({ onClick, icon, dataX, disabled, ariaLabel = undefined, isActive = undefined, isProcessing = undefined, layoutClassName = undefined }) {
  return <ButtonIconBase className={cx(styles.componentWhite, layoutClassName)} {...{ onClick, icon, dataX, ariaLabel, disabled, isActive, isProcessing }} />
}

export function ButtonIconBlue({ onClick, icon, dataX, disabled, ariaLabel = undefined, isActive = undefined, isProcessing = undefined, layoutClassName = undefined }) {
  return <ButtonIconBase className={cx(styles.componentBlue, layoutClassName)} {...{ onClick, icon, dataX, ariaLabel, disabled, isActive, isProcessing }} />
}

export function ButtonIconBlack({ onClick, icon, dataX, disabled, ariaLabel = undefined, isActive = undefined, isProcessing = undefined, layoutClassName = undefined }) {
  return <ButtonIconBase className={cx(styles.componentBlack, layoutClassName)} {...{ onClick, icon, dataX, ariaLabel, disabled, isActive, isProcessing }} />
}

export function ButtonIconTransparent({ onClick, icon, dataX, disabled, ariaLabel = undefined, isActive = undefined, isProcessing = undefined, layoutClassName = undefined }) {
  return <ButtonIconBase className={cx(styles.componentTransparent, layoutClassName)} {...{ onClick, icon, dataX, ariaLabel, disabled, isActive, isProcessing }} />
}

function ButtonIconBase({ onClick, icon, dataX, disabled, isActive, isProcessing, ariaLabel, className }) {
  return (
    <button
      type='button'
      data-x={dataX}
      aria-label={ariaLabel}
      className={cx(
        styles.componentBase,
        isActive && styles.isActive,
        isProcessing && styles.isProcessing,
        className
      )}
      {...{ onClick, disabled }}
    >
      <div className={styles.iconContainer}>
        {isProcessing
          ? <Loader layoutClassName={styles.loaderLayout} />
          : <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
        }
      </div>
    </button>
  )
}
